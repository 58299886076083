<template>
  <div class="info-board-container">
    <div class="source-info-item">
      <div class="sourceInfo-title">应用</div>
      <div class="source-info-detail app">
        <div class="app-info-wrapper">
          <div class="app-avatar">
            <img :src="getCreativeUrl(sourceInfo.iconUrl)" :alt="sourceInfo.appName" />
          </div>
          <div class="app-info-detail">
            <div class="app-name">
              <AndroidSvg class="os-icon" v-if="+sourceInfo.os === 1" />
              <IosSvg class="os-icon" v-else />
              {{ sourceInfo.appName }}
            </div>
            <div class="app-id">ID:{{ query.appId }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="source-info-item">
      <div class="sourceInfo-title">广告位</div>
      <div class="source-info-detail other">{{ sourceInfo.placeName }}</div>
    </div>
    <div class="source-info-item">
      <div class="sourceInfo-title">广告样式</div>
      <div class="source-info-detail other">{{ sourceInfo.positionName }}</div>
    </div>
    <div class="source-info-item">
      <div class="sourceInfo-title">流量分组</div>
      <div class="source-info-detail other">{{ sourceInfo.groupName }}</div>
    </div>
    <div class="source-info-item">
      <div class="sourceInfo-title">A/B测试分组</div>
      <div class="source-info-detail other">{{ sourceInfo.testName }}</div>
    </div>
  </div>
</template>

<script>
import { getNames } from '@/api/common'
import IosSvg from '@/assets/icons/ios.svg?inline'
import AndroidSvg from '@/assets/icons/android.svg?inline'
import { mapState } from 'vuex'

export default {
  components: { AndroidSvg, IosSvg },
  data () {
    return {
      sourceInfo: {
        positionName: '开屏',
        groupName: '默认分组',
        appName: '糖豆',
        placeName: '糖豆-安卓-播放页前贴',
        testName: 'B组（不带OPPO）',
        iconUrl: 'https://pic.netbian.com/uploads/allimg/230306/223844-1678113524f625.jpg',
        os: 1
      }
    }
  },
  props: {
    query: {
      default: () => ({}),
      type: Object
    }
  },
  mounted () {
    this.getSourceInfo()
  },
  computed: {
    ...mapState({
      isAdmin: (state) => state.user.isAdmin,
      // 动态主路由
      allRoutes: (state) => state.permission.allRoutes,
      resourceUrl: (state) => state.autoweb.resourceUrl
    })
  },
  methods: {
    async getSourceInfo () {
      const { data = {} } = await getNames(this.query)
      data.appName && (this.sourceInfo = data)
    },
    getCreativeUrl (url) {
      if (url) {
        return this.resourceUrl + url
      } else {
        return '/images/app-none.png'
      }
    }
  }
}
</script>

<style lang="less" scoped>
@padLeft: 15px;
.info-board-container {
  border-radius: 5px;
  height: 100px;
  background: #fff;
  padding: 0 10px;
  margin: 0 10px 10px;
  display: flex;
  flex-wrap: nowrap;
  position: relative;
  .source-info-item {
    max-width: 20%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    .sourceInfo-title {
      height: 30%;
      // text-align: center;
      line-height: 50px;
      font-weight: 600;
      padding-left: @padLeft;
      color: #000;
      font-size: 16px;
    }
    .source-info-detail {
      flex-grow: 1;
      height: 0;
      box-sizing: border-box;
      position: relative;
      &.other {
        width: 100%;
        line-height: 55px;
        // text-align: center;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        padding: 0 10px;
        padding-left: @padLeft;
        font-size: 13px;
      }
      &.app {
        padding: 12px 40px;
        padding-left: @padLeft;
        .app-info-wrapper {
          height: 100%;
          position: relative;
          box-sizing: border-box;
          display: flex;

          .app-avatar {
            height: 100%;
            aspect-ratio: 1/1;
            border-radius: 5px;
            overflow: hidden;
            min-width: 42px;
            img {
              // aspect-ratio: 1/1;
              height: 40px;
              height: 40px;
              border-radius: 10px;
              // border: 1px solid #ccc;
            }
          }
          .app-info-detail {
            flex-grow: 1;
            padding: 0px 8px;
            position: relative;
            width: 100%;
            .app-name {
              width: 100%;
              font-size: 14px;
              line-height: 20px;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              .os-icon {
                width: 13px;
                height: 13px;
                vertical-align: top;
                margin-top: 3px;
              }
            }
            .app-id {
              font-size: 12px;
              line-height: 20px;
            }
          }
        }
      }
    }
  }
}
</style>
